import { AfterViewInit, Component, OnInit } from '@angular/core'
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router'
import { BehaviorSubject } from 'rxjs'

declare const ga: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent implements OnInit, AfterViewInit {
  isLoaderVisible$ = new BehaviorSubject<boolean>(true)

  constructor(private router: Router) {
    // Redirection for users under IE
    if (typeof document['documentMode'] === 'number') {
      this.router.navigate(['/ie'])
    }
  }

  ngOnInit() {
    if (document.getElementById('app-loader')) {
      this.isLoaderVisible$.next(false)
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLoaderVisible$.next(true)
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.isLoaderVisible$.next(false)

        if (event instanceof NavigationEnd) {
          this.pageChangeAlert(event)
        }
      }
    })
  }

  private pageChangeAlert(event: NavigationEnd) {
    if (typeof ga !== 'undefined') {
      ga('set', 'page', event.urlAfterRedirects)
      ga('send', 'pageview')
    }
  }
}
